<template>
  <div class="video">
    <div class="release-video1">
      <el-button type="danger" size="small" @click="addVideo">发表视频</el-button>
      
    </div>
    <div class="release-video">
      <el-button type="danger" @click="addVideo">发表视频</el-button>
      
    </div>
    <ul>
      <li class="video-item" v-for="item in onlineVideo" :key="item.videoId">
        <video
        controls
        style="width:500px;height:400px"
        id="video"
        :poster="item.pageUrl"
        :src="item.videoUrl"></video>
        <p class="authorName">发布者：{{item.authorName}}</p>

        <div class="conter">
          <span :title="item.videoTitle" class="videoTitle">
            {{item.videoTitle}}
          </span>
          <span>{{item.createTime}}</span>
        </div>
      </li>
    </ul>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[9, 15, 30]"
        :page-size="count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <release-video :videoShow=videoShow @addCancel = "addCancel"></release-video>
  </div>
</template>

<script>
import releaseVideo from './release-video.vue';
export default {
  components: { releaseVideo },
  name: 'BbjtwebsiteVideo',

  data() {
    return {
      page: 1,
      count: 9,
      total: null,
      currentPage: 2,
      onlineVideo: [],
      videoShow: false,
      languages:""
    };
  },

  mounted() {
    this.lang = String(localStorage.getItem('lang'))
    console.log(this.lang)
      // console.log('ok')
      if(this.lang == "zh"){
        this.languages = 0
      }else if(this.lang == "en"){
        this.languages = 1
      }else if(this.lang == "es"){
        this.languages ==6
      }else if(this.lang == "ru"){
        this.languages = 2
      }else if(this.lang == "de"){
        this.languages = 3
      }else if(this.lang == "fr"){
        this.languages = 4
      }else if(this.lang == "ar"){
          this.languages = 5
      }
      console.log(this.languages)
    this.videoList()
    this.$nextTick

  },

  methods: {
    async videoList() {
      let res = await this.api.get(this.apiPath.onlineVideo,{page:this.page,count:this.count,languages:this.languages});
      console.log(res)
      this.onlineVideo = res.data.resultList
      this.total = res.data.totalRecord
      this.currentPage = res.data.pageNum
    },
    handleSizeChange(val) {
      this.count = val
      this.videoList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.videoList()
    },
    addCancel(msg) {
      this.videoShow = msg
    },
    addVideo() {
      this.videoShow = true
    }
  },
};
</script>

<style lang="less" scoped>
.release-video1 {
  display: none;
}
.video {
  position: relative;
  padding: 5% 5%;
  .release-video {
    position: absolute;
    top: 10px;
    right: 10%;
    height: 100px;
  }
  ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .video-item {
      margin-right: 20px;
      border: 1px solid #eee;
      margin-bottom: 10px;
      // #video {
      //   width: 100%;
      //   height: 300px;
      // }
      .conter {
        display: flex;
        justify-content: space-around;
        color: #000;
        .videoTitle {
          white-space:nowrap;/*强制单行显示*/
          text-overflow:ellipsis;/*超出部分省略号表示*/
          overflow:hidden;/*超出部分隐藏*/
          width: 260px;/*设置显示的最大宽度*/
        }
      }
      .authorName {
        margin-left: 20px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .release-video1 {
      display: block;
      text-align: end;
      margin-bottom: 15px;
    }
    .release-video {
      display: none;
    }
    .video-item {
      margin-right: 0 !important;
      video {
        width: 100% !important;
      }
      
    }
  }
}

</style>
<style>
/* .el-select-dropdown {
  position: absolute;
  top: 1530px !important;
  left: 166px !important;
} */
.el-pagination {
  display: flex;
  flex-wrap: wrap;
}
</style>