<template>
  <div>
    <el-dialog close="dialog" title="上传视频" :visible.sync="dialogFormVisible" :before-close="cancel">
      <div slot="footer" class="dialog-footer">
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
            <el-form-item label="视频标题">
              <el-input v-model="form.video_title"></el-input>
            </el-form-item>
            <el-form-item label="视频描述">
              <el-input type="textarea" v-model="form.video_describe"></el-input>
            </el-form-item>
            <el-form-item label="视频" prop="video_url">
              <el-upload
                class="avatar-uploader el-upload--text"
                action="https://onlinemall.bbjt.cc/cms/ossUser/uploadVideo"
                :show-file-list="false"
                :on-success="handleVideoSuccess"
                :before-upload="beforeUploadVideo"
                :on-progress="uploadVideoProcess"
              >
                <video
                  v-if="form.video_url != '' && videoFlag == false"
                  :src="form.video_url"
                  class="avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <i
                  v-else-if="form.video_url == '' && videoFlag == false"
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
                <el-progress
                  v-if="videoFlag == true"
                  type="circle"
                  :percentage="videoUploadPercent"
                  style="margin-top:30px;"
                ></el-progress>
              </el-upload>
            </el-form-item>
          </el-form>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'BbjtwebsiteReleaseVideo',
  props:{
    videoShow:{
      type: Boolean,
      default:false
    }
  },

  data() {
    return {
      dialogFormVisible: false,
      videoFlag: false,
      form: {
        video_title: '',
        video_describe: '',
        video_url: '',
        author_picture_url: '',
        author_name: '',
        user_phone: ''
      },
      rules: {
        video_url: [
          { required: true, message: '请上传视频', trigger: 'blur' }
        ],
      },
    };
  },

  watch: {
    videoShow: {
      handler: function (val) {
        this.dialogFormVisible = val;
      },
      immediate: true,
      deep: true,
    }
  },

  mounted() {

  },

  methods: {
    cancel() {
      this.$emit("addCancel", false);
    },
    async determine() {
      // 上传视频
      let userMap = JSON.parse(localStorage.getItem("userMap"));
      if(!userMap) {
        this.$router.push({
          path: '/login'
        })
        return
      }
      this.form.author_picture_url = userMap.headImg
      this.page_url = this.form.video_url + '?x-oss-process=video/snapshot,t_2500,m_fast,f_jpg,ar_auto'
      this.form.author_name = userMap.username
      this.form.user_phone = userMap.phone
      let res = await this.api.post(this.apiPath.inVideo,this.form);
      if(res.code == 0) {
        this.$message({
          message: '视频上传成功，请等待审核！',
          type: 'success'
        });
        this.$emit("addCancel", false);
        this.form = {}
      } else {
        this.$message.error(res.message)
      }
    },
    beforeUploadVideo(file) {
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/helf',
          'video/hevc',
          'video/mov',
        ].indexOf(file.type) === -1
      ) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
    },
    // 上传进度显示
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true
      this.videoUploadPercent = Math.floor(event.percent)
    },

    // 获取上传图片地址
    handleVideoSuccess(res, file) {
      this.videoFlag = false
      this.videoUploadPercent = 0
      if (res.code === 0) {
        this.form.video_url = res.message
      } else {
        this.$message.error('视频上传失败，请重新上传！')
      }
    }
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  width: 200px;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}
.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
</style>
<style>
@media screen and (max-width: 750px) {
  .el-dialog {
    width: 100%;
  }
}
</style>
